import z from 'zod';
import { zodTDateIso } from '~/lib/zod';
import { ChannelType } from '~/lib/enum';
import * as v from 'valibot';
import form from '~/components/valibot/form';

export enum NotificationType {
  // Order Status Notifications
  ORDER_SUBMITTED = 'ORDER_SUBMITTED',
  ORDER_PAID = 'ORDER_PAID',
  ORDER_DELIVERED = 'ORDER_DELIVERED',
  ORDER_QUOTED = 'ORDER_QUOTED',
  QUOTE_ACCEPTED = 'QUOTE_ACCEPTED',
  QUOTE_REJECTED = 'QUOTE_REJECTED',

  // Payment Notifications
  PAYMENT_FAILED = 'PAYMENT_FAILED',

  // Job Notifications
  JOB_INPUT_REQUIRED = 'JOB_INPUT_REQUIRED',
  JOB_UPLOADED_TO_EDITOR = 'JOB_UPLOADED_TO_EDITOR',
  JOB_UPLOADED_FROM_EDITOR = 'JOB_UPLOADED_FROM_EDITOR',
  JOB_ACTION_REQUIRED = 'JOB_ACTION_REQUIRED',
}

export const NotificationTypes = Object.values(NotificationType);

export const NotificationChannelSchema = v.record(
  v.enum(NotificationType),
  v.record(v.enum(ChannelType), form.boolean),
);

export interface UserDataNotifiedTimestamps {
  notified_appointments?: TDateISO;
}

export interface UserDataNotifiedBoolean {
  notified_invited?: boolean;
  notified_password?: boolean;
}

export type UserDataNotified = UserDataNotifiedTimestamps & UserDataNotifiedBoolean;

export interface UserData extends UserDataNotified {
  first?: string;
  last?: string;
  title?: string;
  password?: string;
  password_change?: boolean;
  reset?: boolean;
  google?: boolean;
  customers: Record<string, {}>;
  editor?: string;
  office?: TPhone;
  provider?: string;
  onboarded?: boolean;
  accepted?: TDateISO;
  imported?: boolean;
  sms?: boolean;
  disabled?: boolean;

  invite_send?: boolean;
  invite_accepted?: TDateISO;

  verified?: boolean;
  verified_send?: boolean;
  verified_sent?: TDateISO;

  twilio_worker_enabled?: boolean;
  twilio_worker_sid?: string;
  twilio_worker_priority?: number;

  notifications?: Partial<Record<NotificationType, Partial<Record<ChannelType, boolean>>>>;
}

export function USER_DEFAULT_DATA(): Omit<UserData, 'first' | 'last' | 'email'> {
  return {
    customers: {},
  };
}

export const InvitedUserSchema = z.object({
  invite_send: z.boolean(),
  invite_accepted: zodTDateIso().optional(),
  invite_notified: z.boolean(),
});

export const EditorUserSchema = InvitedUserSchema;
